<template>
    <div class="page-header">
        <li class="firstt">{{ title }}</li>
        <div class="col-xl-4 col-lg-3 col-md-2 col-sm-2 col-2"></div>
        <div class="row">
        </div>
        <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-3 d-flex justify-content-end">
            <div class="custom-search w-100" v-if="haveSearch">
                <input type="text" class="search-query w-100" v-model="search" placeholder="Pesquisar"
                       @keypress="emitWithEnter($event)">
                <i class="icon-search1" @click="$emit('searching', search)"></i>
            </div>
            <a class="btn btn-primary" id="filter" data-toggle="modal" data-target="#customModalTwo"
               @click="$emit('filter')" v-if="filter">
                Filtro
            </a>
            <div v-if="system">
                <Select2
                        placeholder="Selecione"
                        :options="[
                                {id:'all', text:'Todos os Sistemas'},
                                {id:'R2Disponibilidade', text:'R2 Disponibilidade'},
                                {id:'R2Loteamentos', text:'R2 Loteamentos'}
                                ]"
                        :settings="{width: $widthMd(454) ? '180px' : '220px'}"
                        @select="setSystem"
                        v-model="systemChoose">
                </Select2>
            </div>
        </div>
    </div>
</template>
<script>

import {useI18n} from "vue-i18n";
import {useToast} from "vue-toastification";
import Helper from '@/Helpers/helper';
import Select2 from 'vue3-select2-component';

export default {
    props: {
        title: {
            type: String,
            default: "Documentos"
        },
        filter: {
            default: false,
            type: Boolean,
        },
        haveFilter: {
            default: false,
            type: Boolean,
        },
        haveSearch: {
            default: false,
            type: Boolean,
        },
        system: {
            type: String,
        }
    },
    mounted(){
        this.systemChoose = this.system;
    },
    watch:{
        system() {
            this.systemChoose = this.system;
        }
    },
    components: {
        Select2,
    },
    setup() {
        const {t} = useI18n();
        const toast = useToast();
        return {t, toast}
    },

    data() {
        return {
            search: null,
            systemChoose: null,
        }
    },

    methods: {
        setSystem({id}) {
            this.$emit('setSystem', id);
        },
        emitWithEnter(log) {
            if (log.key == 'Enter') {
                this.$emit('searching', this.search);
            }
        },

        viewDocument(originalDocumentUrl) {
            Helper.viewDocument(originalDocumentUrl);
        },
    }
}
</script>

<style>
.firstt {
    border-radius: 0px;
    background: transparent;
    margin: 0;
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    padding: 1rem 0 0.5rem 0;
    font-size: 1.5rem;
    align-items: center;
}

.btn-custom {
    border: none;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    transition-duration: 0.4s;
    cursor: pointer;
}

.bg-btn {
    background-color: white !important;
    color: black !important;
    border: 1px solid #0e5e4e;
}

.bg-btn:hover, .bg-btn:focus {
    background-color: #0e5e4e !important;
    color: white !important;
}

.colorHov:hover {
    color: white;
}

.search-query::placeholder {
    color: #0e5e4e;
    opacity: 0.9
}
</style>
