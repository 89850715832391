export default {
    translate(word) {
        switch (word) {
            case null:
                return 'Aguardando';
            case undefined:
                return 'Aguardando';
            case 'signed':
                return 'Assinado';
            case 'running':
                return 'Aguardando';
            case 'canceled':
                return 'Cancelado';
            case 'expired':
                return 'Expirado';
            case 'closed':
                return 'Assinado';
            case 'sign':
                return 'Assinar';
            case 'approve':
                return 'Aprovador';
            case 'party':
                return 'Participante';
            case 'witness':
                return 'Testemunha';
            case 'intervening':
                return 'Interveniente';
            case 'receipt':
                return 'Recebimento';
            case 'endorser':
                return 'Endossante';
            case 'endorsee':
                return 'Endossatário';
            case 'administrator':
                return 'Administrador';
            case 'guarantor':
                return 'Avalista';
            case 'transferor':
                return 'Cedente';
            case 'transferee':
                return 'Cessionário';
            case 'contractee':
                return 'Contratada';
            case 'contractor':
                return 'Contratante';
            case 'joint_debtor':
                return 'Devedor Solidário';
            case 'issuer':
                return 'Emitente';
            case 'manager':
                return 'Gestor';
            case 'buyer':
                return 'Comprador';
            case 'seller':
                return 'Vendedor';
            case 'attorney':
                return 'Procurador';
            case 'legal_representative':
                return 'Representante Legal';
            case 'co_responsible':
                return 'Responsável Solidário';
            case 'validator':
                return 'Validador';
            case 'ratify':
                return 'Homologador';
            case 'lessor':
                return 'Locador';
            case 'lessee':
                return 'Locatário';
            case 'surety':
                return 'Fiador';
        }
    },

    selectClass(status){
        switch (status) {
            case null:
                return 'signer-running'
            case 'signed':
                return 'signer-signed'
            case 'canceled':
                return 'signer-canceled'
        }
    },

    viewDocument(linkDocument) {
        window.open(linkDocument, '_blank');
    },

    signaturesQuantity(item){
        let alreadySigned = [];

        item.signers.forEach((signed) => {
            if (signed.status_of_signature === 'signed') {
                alreadySigned.push('assinou')
            }
        });

        return alreadySigned.length + "/" + item.signers.length
    },
}
